import { createTheme, type Theme } from "@mui/material";
import { grey } from "@mui/material/colors";

import {
  ALERT_COLORS,
  APP_BAR_COLORS,
  BACKGROUND_DARK_MODE,
  ERROR_COLOR,
  GRAY_00,
  GRAY_E0,
  GRAY_E5,
  GRAY_F0,
  GRAY_F5,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DARK,
  SECONDARY_COLORS,
  TYPOGRAPHY_COLORS,
} from "./constants";
import { type TPaletteMode } from "./types";

declare module "@mui/material/Alert" {
  interface AlertPropsVariantOverrides {
    insight?: true;
  }
}

export function getTheme(paletteMode: TPaletteMode): Theme {
  const modeIsLight = paletteMode === "light";
  const textColor = modeIsLight ? GRAY_00 : GRAY_F0;
  const backgroundColor = modeIsLight ? "white" : GRAY_00;
  return createTheme({
    shape: {
      borderRadius: 8,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 400,
        md: 600,
        lg: 900,
        xl: 1400,
      },
    },
    zIndex: {
      appBar: 2000,
    },
    typography: {
      allVariants: {
        fontFamily:
          '"SF Pro", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif',
        color: textColor,
        fontWeight: 400,
        lineHeight: "inherit",
      },
      h1: {
        fontSize: "1.75rem",
      },
      h2: {
        fontSize: "1.5rem",
      },
      h3: {
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
      },
      h4: {
        fontSize: "1rem",
      },
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.875rem",
        color: TYPOGRAPHY_COLORS.body2[paletteMode],
      },
      caption: {
        lineHeight: "1rem",
      },
    },
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLORS[paletteMode],
      },
      error: {
        main: ERROR_COLOR,
        light: "#EF5350",
        contrastText: "#FFF1F0",
      },
      info: {
        main: "#1890FF",
        light: "#ECF4FA",
        contrastText: "#E5F5FD",
      },
      warning: {
        main: "#E7A600",
        light: "#FAECEC",
      },
      success: {
        main: "#2E7D32",
        light: "#4CAF50",
        contrastText: "#EDF7ED",
      },
      background: {
        default: modeIsLight ? GRAY_E0 : BACKGROUND_DARK_MODE,
        paper: backgroundColor,
      },
      text: {
        primary: textColor,
        secondary: textColor,
      },
      action: {
        active: PRIMARY_COLOR,
      },
      legacyFullScreenDialogBackground: APP_BAR_COLORS.backgroundColors.light,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "unset",
            fontSize: "1.2rem",
            lineHeight: "2.25rem",
            fontWeight: "600",
            boxShadow: "none",
          },
          sizeSmall: {
            fontWeight: 400,
            fontSize: "1rem",
            padding: "2px 12px",
          },
          contained: {
            "&:hover": {
              color: GRAY_F0,
            },
          },
          outlined: {
            color: modeIsLight ? PRIMARY_COLOR : GRAY_F0,
            backgroundColor: modeIsLight ? "transparent" : "#ffffff10",
          },
          text: {
            color: modeIsLight ? PRIMARY_COLOR : GRAY_F0,
          },
        },
        defaultProps: {
          disableRipple: true,
          draggable: false,
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
        variants: [
          {
            props: { variant: "elevation" },
            style: {
              backgroundColor: GRAY_F5,
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              backgroundColor: modeIsLight ? "#ffffff" : "transparent",
            },
          },
        ],
      },
      MuiCardActionArea: {
        defaultProps: {
          disableRipple: true,
          draggable: false,
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            backgroundColor,
          },
          title: {
            color: TYPOGRAPHY_COLORS.body2[paletteMode],
            fontWeight: "bold",
          },
          avatar: {
            color: modeIsLight ? PRIMARY_COLOR : GRAY_F0,
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "always",
        },
        styleOverrides: {
          root: {
            color: modeIsLight ? "#0068b3" : SECONDARY_COLORS.dark,
            textDecorationColor: modeIsLight ? "#0068b3" : SECONDARY_COLORS.dark,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardWarning: ALERT_COLORS.warning,
          standardInfo: ALERT_COLORS.info,
          standardSuccess: ALERT_COLORS.success,
        },
        variants: [
          {
            props: { variant: "insight" },
            style: ALERT_COLORS.insight,
          },
        ],
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            // See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
            paddingTop: "env(safe-area-inset-top)",
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            backgroundColor: APP_BAR_COLORS.backgroundColors[paletteMode],
            color: APP_BAR_COLORS.colors[paletteMode],
            fontSize: "1.25rem",
            boxShadow: "none",
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            width: "100%",
            marginBottom: "env(safe-area-inset-bottom)",
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            maxWidth: "unset",
            "&:hover": {
              // FIXME - clean up global antd global.less hover styles. We don't need this override.
              color: "unset",
            },
            "&:hover.Mui-selected": {
              // FIXME - clean up global antd global.less hover styles. We don't need this override.
              color: PRIMARY_COLOR,
            },
          },
          label: {
            fontSize: "0.75rem",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            "&.Mui-selected": {
              fontSize: "0.75rem",
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 16,
          },
          paperFullScreen: {
            borderRadius: 0,
            // See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            maxWidth: "var(--page-max-width)",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            // See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
            paddingLeft: "env(safe-area-inset-left)",
            maxWidth: "var(--page-max-width)",
            margin: "0 auto",
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          variant: "h2",
          textAlign: "center",
        },
        styleOverrides: {
          root: {
            fontWeight: "bold",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            gap: 16,
            padding: 16,
            backgroundColor,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            "::before": {
              backgroundColor: "unset",
            },
            backgroundColor: modeIsLight ? "#ffffff" : "unset",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
            ".MuiAccordionSummary-content": {
              alignItems: "center",
              marginY: 1,
              "&.Mui-expanded": {
                marginY: 1,
              },
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            "&.MuiLinearProgress-determinate": {
              borderRadius: 8,
            },
            height: "1rem",
            backgroundColor: GRAY_E5,
          },
        },
      },
      /**
       * FIXME: Extend theme components type to include additional components from @mui/x-date-pickers
       * @ts-expect-error 'MuiPickersMonth' doesn't exist on regular theme components. */
      MuiPickersMonth: {
        styleOverrides: {
          root: {
            "button:disabled": {
              color: grey[400],
            },
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          root: {
            "button:disabled": {
              color: grey[400],
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${grey[300]}`,
            "& .MuiTabs-indicator": {
              backgroundColor: grey[900],
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            textTransform: "none",
            "&.Mui-selected": {
              fontWeight: 600,
              color: grey[900],
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            boxShadow: "none",
          },
          primary: {
            backgroundColor: PRIMARY_COLOR_DARK,
            "&:hover": {
              backgroundColor: PRIMARY_COLOR_DARK,
            },
          },
        },
      },
      MuiListItemButton: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
    },
  });
}
